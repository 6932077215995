<template>
    <div class="classCenter">
        <!-- 头部导航 -->
        <van-nav-bar class="navBar" fixed :placeholder="true" @click-left="isDropdownMenu= !isDropdownMenu">
            <template #left>
                <!-- <van-icon name="arrow-left" size="22" color="#141414" /> -->
                <van-icon name="apps-o" size="22" color="#141414"/>
            </template>
            <template #title>
                <span style="font-size:16px;font-weight:500;">课程详情</span>
                <!-- <van-icon name="exchange" size="18" /> -->
            </template>
            <template #right>
                <!-- <van-icon name="search" size="25" color="#141414" @click="toSearch" /> -->
                <van-icon size="22" name="wap-home-o" @click="$router.push('/')" />
            </template>
        </van-nav-bar>
        <div class="downMenuModal" v-if="isDropdownMenu" @click="isDropdownMenu = false">
            <div v-if="isDropdownMenu" class="dropdownMenu">
                <div class="menuItem" @click="$router.push('/xuanke')">
                    <span>选课中心</span>
                </div>
                <div class="menuItem" @click="$router.push('/tiku')">
                    <span>题库</span>
                </div>
                <div class="menuItem" @click="$router.push('/homeLiveList')">
                    <span>直播</span>
                </div>
                <div class="menuItem" @click="$router.push('/learning')">
                    <span>学习中心</span>
                </div>
                <div class="menuItem menuItem5" @click="downApp">
                    <span>下载APP</span>
                </div>
            </div>
        </div>

        <!-- 首图 height="217px"-->
        <div class="firstImg">
            <van-image class="firstImg_img" fit="cover" width="100%" height="100%" :src="detailData.coverPath" />
        </div>

        <!-- 中部 标题、内容区 -->
        <div class="content">
            <p class="p1">
                <span v-show="detailData.isTextbook == 1" class="span1">教辅包邮</span>
                {{detailData.courseName}}
            </p>

            <!-- 全程直播授课，智能题库刷题。 -->
            <div class="p2">
                <p v-show="detailData.courseSecondName">{{detailData.courseSecondName}}</p>
            </div>

            <!-- 价格 -->
            <div class="p3">
                <div v-if="detailData.isFree == 1 && detailData.isBuy == 0">
                    <span class="span1">
                        <span v-if="detailData.coursePackagePriceList && detailData.coursePackagePriceList[0] && detailData.coursePackagePriceList[0].discountPrice">
                            ¥{{detailData.coursePackagePriceList[index].discountPrice}}
                        </span>
                        <span v-else>¥{{detailData.coursePackagePriceList[index].price}}</span>
                    </span>
                    <span class="span2" v-if="detailData.coursePackagePriceList && detailData.coursePackagePriceList[0].discountPrice">
                        ¥{{detailData.coursePackagePriceList[index].price}}
                    </span>

                    <span class="span3" v-if="detailData.coursePackagePriceList && detailData.coursePackagePriceList[0].discountLeftTime">
                        折扣倒计时:
                    </span>
                    <van-count-down class="span4" v-if="detailData.coursePackagePriceList && detailData.coursePackagePriceList[0].discountLeftTime" :time="detailData.coursePackagePriceList[index].discountLeftTime * 1000" format="DD天HH时mm分ss秒" />
                </div>
                <div v-else-if="detailData.isFree == 1 && detailData.isBuy == 1">已购买</div>
                <div v-else-if="detailData.isFree == 0">免费</div>
            </div>

            <!-- 赠送课程名称 -->
            <div v-if="detailData.giveCourseNames && detailData.giveCourseNames[0]" class="p4">
                <img src="@/assets/all_Icon/drawable-xxhdpi/my_gift.png" style="width:'30px';height:30px" />
                <span>{{detailData.giveCourseNames[0]}}</span>
            </div>
            <div v-else style="width:100%;height:0.5px;background:#ccc;margin-bottom:28px"></div>

            <!-- 有效期 -->
            <div class="p5" v-if="detailData.isBuy==0 && detailData.coursePackagePriceList && detailData.coursePackagePriceList[0]" @click="pickTime">
                <div v-if="detailData.coursePackagePriceList[0].effectiveEtime">
                    <span class="span1">有效期</span><span class="span2">购买之日至：{{youXiaoDate}}</span>
                </div>
                <div v-if="detailData.coursePackagePriceList[0].effectiveMonth">
                    <span class="span1">有效期</span><span class="span3">{{youXiaoDate}}个月</span>
                </div>
                <van-icon name="arrow" size="18px" />
            </div>
            <!-- 特色服务 -->
            <div class="p6">
                <div v-if="detailData.specialServiceNames">
                    <span class="span1">特色服务</span>
                    <span class="span2" v-for="(item,i) in detailData.specialServiceNames" :key="i">{{item}}</span>
                </div>
            </div>
            <!-- 优惠券 -->
            <div class="p7" v-if="detailData.courseCouponList && detailData.courseCouponList.length>0">
                <div>
                    <span class="span1">优惠券</span>
                    <span class="span2">暂无优惠券</span>
                </div>
                <div class="div2" @click="showCoupon = true">
                    <span class="span3">立即领取</span>
                    <van-icon name="arrow" size="18px" />
                </div>

            </div>
        </div>

        <!-- 下部选项卡、图片区 -->
        <van-tabs class="vanTabs" v-model="active" swipeable animated color="#5D7DFF" title-active-color="#5D7DFF">
            <van-tab title="课程详情" title-style="font-size:15px">
                <div v-html="detailData.detail" class="tabLeft"></div>
                <div style="height:80px;width:100%"></div>
            </van-tab>
            <van-tab title="课程安排" title-style="font-size:15px">
                <div class="tabRight" style="min-height:50vh">
                    <!-- detailData:课程详情 coursePackageId:课程id-->
                    <classPlan v-if="detailData" :detailData="detailData" :coursePackageId="coursePackageId" />
                    <div style="height:80px;width:100%"></div>
                </div>
            </van-tab>
        </van-tabs>

        <!-- 底部按钮区 -->
        <div class="footBtn">
            <div @click="to53kefu">
                <van-icon name="service-o" /><span>客服</span>
            </div>
            <div class="baoMingBtn" v-if="detailData.isFree == 0 || detailData.isBuy == 1" @click="toSignUp('study')">去学习</div>
            <div class="baoMingBtn" v-if="detailData.isFree == 1 && detailData.isBuy == 0" @click="toSignUp('baoMing')">立即报名</div>
        </div>

        <!-- 选择时间 弹窗 -->
        <van-popup v-model="showPicker" round position="bottom">
            <van-picker title="有效期" show-toolbar :columns="columns" @cancel="showPicker = false" @confirm="onConfirm" />
        </van-popup>

        <!-- 领取优惠券 弹窗 -->
        <van-popup v-model="showCoupon" round position="bottom">
            <div class="CouponModel">
                <div class="title">
                    <span class="sp1"></span><span class="sp2">选择优惠券</span>
                </div>
                <div class="couponCard">
                    <couponCard2 v-for="(item,i) in detailData.courseCouponList" :key="i" :data="item" @receiveCoupon="receiveCoupon" />
                    <!-- <couponCard2 v-for="(item,i) in couponList" :key="i" :data="item" @receiveCoupon="receiveCoupon" /> -->
                </div>
                <div class="closeBtn" @click="showCoupon= false">关闭</div>
            </div>
        </van-popup>

        <!-- 客服弹窗 -->
        <van-popup v-model="showKeFu" position="bottom">
            <div class="keFuPopup">
                <div class="keFu_title">
                    <van-icon size="20px" name="arrow-left" @click="showKeFu=false" />
                    <van-icon size="20px" name="cross" @click="showKeFu=false" />
                </div>
                <!-- <iframe class="keFu_iframe" src="http://www.canet.com.cn/kefu/" frameborder="0"></iframe> -->
                <iframe class="keFu_iframe" src="https://tb.53kf.com/code/client/10093167/1" frameborder="0"></iframe>
            </div>
        </van-popup>

    </div>
</template>

<script>
import classPlan from '@/pages/classCenter/components/classPlan'
import couponCard2 from '@/pages/classCenter/components/couponCard2'

import { filterTime } from '@/utils/utils.js'
import { packageDetail,courseDetailByPackageId, courseCoupon, createOrder } from '@/api/selectClass.js'
import { setReceive } from "@/api/login.js";
import { coures } from '@/api/study_course.js'

export default {
    components: { classPlan, couponCard2 },
    data() {
        return {
            active: 0,
            isDropdownMenu:false,

            // 套餐id:coursePackageId > 课程id:courseId > 章节id:chapterId
            coursePackageId: '', // 课程id

            detailData: {}, // 获取的页面详情data
            
            showPicker: false, // 时间选择器 弹窗
            youXiaoDate:'', //绑定的有效期
            columns: [ // 有效期时间选择器数据
                // '2022年05月31日'
            ],
            index:0, //当前选择的有效期的索引
            
            showCoupon: false,// 优惠券 弹窗
            couponList: [], //优惠券数据

            showKeFu:false,
        }
    },
    created() {
        console.log('选课中心路径参数111------',this.$route.params)
        if (this.$route.params.coursePackageId) {
            this.coursePackageId = this.$route.params.coursePackageId
            this.packageDetailFun()
        }
    },
    methods: {
        // 跳搜索页
        toSearch() {
            this.$router.push({ path: "/search" });
        },
        downApp(){
            window.location.href = 'https://www.saen.com/app/';
        },
        // 获取课程详情
        packageDetailFun() {
            let data = new FormData()
            data.append('coursePackageId', this.coursePackageId)
            courseDetailByPackageId(data).then(res => {
                // console.log('classCenter-详情---',res)
                if (res.data.code == 0) {
                    this.detailData = res.data.data
                    document.title = res.data.data.courseName
                    
                    // 有效期以固定日期计算
                    if(this.detailData.effectiveType == 1 && this.detailData.coursePackagePriceList.length>0){
                        this.detailData.coursePackagePriceList.forEach((item,i) => {
                            item.effectiveEtime = filterTime(item.effectiveEtime)
                            this.columns.push(item.effectiveEtime)
                        })
                        this.youXiaoDate = this.detailData.coursePackagePriceList[0].effectiveEtime
                    }
                    // 有效期以天数计算
                    if(this.detailData.effectiveType == 0 && this.detailData.coursePackagePriceList.length>0){
                        this.detailData.coursePackagePriceList.forEach((item,i) => {
                            this.columns.push(item.effectiveMonth)
                        })
                        this.youXiaoDate = this.detailData.coursePackagePriceList[0].effectiveMonth
                    }
                }
            })
        },
        // 选择有效期
        pickTime() {
            this.showPicker = true
        },
        // 领取优惠券
        receiveCoupon(id) {
            // this.$toast('领取优惠券')
            let data = new FormData()
            data.append('courseCouponId', id)
            courseCoupon(data).then(res => {
                this.$toast(res.data.msg)
                this.packageDetailFun()
            })

        },
        // 跳53客服
        to53kefu() {
            // window.location.href = "http://www.canet.com.cn/kefu/"
            this.showKeFu = true
        },
        // 去报名 去学习
        async toSignUp(path) {
            if (path == 'study') {
                let id = this.coursePackageId;
                this.$router.push({path: '/meallist/'+ id+ '/classCenter'})
                if(this.detailData.isFree == 0 && this.detailData.isBuy == 0) this.setReceiveFn()
            }
            if(path == 'baoMing') {
                if (this.detailData.coursePackagePriceList.length < 1) return this.$toast('暂无订单信息')
                let res = await coures() //校验是否登录
                if(res.data.code != 0){return} //未登录不跳转购买页
                this.$router.push({
                    path: `/orderdetail`,
                    query: {
                        coursePackageId: this.coursePackageId,
                        coursePackagePriceId: this.detailData.coursePackagePriceList[this.index].coursePackagePriceId,
                        payType: 1, //支付类型-暂定值：1套餐 2直播
                        isTextbook: this.detailData.isTextbook
                    }
                })
            }
        },
        // 添加到已购课程
        setReceiveFn(){
            let data = new FormData()
            data.append('coursePackageId',this.coursePackageId);
            setReceive(data).then(res => {
                console.log('添加到已购课程--------',res);
                this.$toast('已添加到已购课程')
            })
        },

        // 有效期弹窗  选择有效期
        onConfirm(value, index) {
            console.log(`当前值：${value}, 当前索引：${index}`);
            this.youXiaoDate = value
            this.index = index
            this.showPicker = false
        },
        onChange(picker, value, index) {
            console.log(`当前值：${value}, 当前索引：${index}`);
        },
        onCancel() {
            console.log('取消');
        },
    },
}
</script>

<style lang="less" scoped>
.classCenter {
    width: 100%;
    min-height: 100vh;
    position: relative;
    font-family: PingFangSC-Medium, PingFang SC;

    .downMenuModal{
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;

        .dropdownMenu{
            position: fixed;
            left: 10px;
            top:44px;
            background-color: #fff;
            z-index: 999;
            width: 40%;
            min-height: 200px;
            box-shadow: 10px 10px 10px 0px rgba(0,0,0,0.5);

            .menuItem{
                height: 40px;
                line-height: 40px;
                font-size: 13px;
                padding-left: 20px;
            }
            .menuItem5{
                color: #ef8f27;
            }
        }
    }
    
    

    .firstImg {
        margin-bottom: 23px;
        .firstImg_img{
            min-height: 217px;
        }
    }

    .content {
        padding: 0 14px;
        border-bottom: 8px solid #f0f0f0;
        .p1 {
            font-size: 16px;
            color: #141414;
            margin-bottom: 11px;
            .span1 {
                display: inline-block;
                width: 56px;
                height: 23px;
                text-align: center;
                color: #ffffff;
                line-height: 23px;
                font-size: 11px;
                background: #617fff;
                border-radius: 5px;
            }
        }
        .p2 {
            height: 18px;
            font-size: 13px;
            color: #666666;
            margin-bottom: 12px;
        }
        .p3 {
            font-size: 13px;
            color: #666666;
            margin-bottom: 30px;

            .span1 {
                font-size: 13px;
                color: #e85050;
                margin-right: 10px;
            }
            .span2 {
                font-size: 14px;
                color: #e85050;
                color: #999999;
                text-decoration: line-through;
                margin-right: 10px;
            }
            .span3 {
                font-size: 14px;
                color: #ef8f27;
            }
            .span4 {
                display: inline-block;
                width: 125px;
                color: #ef8f27;
            }
        }
        .p4 {
            display: flex;
            align-items: center;
            height: 60px;
            border-top: 0.5px solid #ccc;
            border-bottom: 0.5px solid #ccc;
            font-size: 13px;
            color: #666666;
            margin-bottom: 25px;

            > img {
                margin-right: 12px;
            }
        }
        .p5 {
            min-height: 18px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 13px;
            color: #999999;
            margin-bottom: 25px;

            .span1 {
                margin-right: 20px;
            }
            .span2 {
                font-size: 13px;
                color: #333333;
            }
            .span3 {
                display: inline-block;
                width: 60px;
                height: 22px;
                line-height: 22px;
                font-size: 13px;
                color: #333333;
                border: 1px solid blue;
                border-radius: 5px;
                text-align: center;
            }
        }
        .p6 {
            font-size: 13px;
            color: #999999;
            margin-bottom: 25px;
            min-height: 18px;

            > div {
                .span1 {
                    margin-right: 20px;
                }
                .span2 {
                    font-size: 13px;
                    color: #333333;
                    margin-right: 15px;
                }
            }
        }
        .p7 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 13px;
            color: #999999;
            margin-bottom: 30px;

            .span1 {
                margin-right: 20px;
            }
            .span2 {
                color: #333333;
            }
            .span3 {
                color: #5f7dff;
            }
            .div2 {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }

    .vanTabs {
        /deep/.van-tab__text{
            font-weight: 600;
        }
        .tabLeft {
            min-height: 500px;
            font-size: 16px;
            padding: 0 1px;
            padding-top: 20px;
        }

        .tabRight {
            min-height: 500px;
            background-color: #f0f0f0;
            padding: 0 20px;
            padding-top: 10px;
        }
    }

    .footBtn {
        background: #fff;
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 60px;
        box-shadow: 0px -4px 5px 0px rgba(0, 0, 0, 0.07);
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 14px;
        color: #5f7dff;
        z-index: 2;

        .baoMingBtn {
            width: 250px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            background: #5f7dff;
            border-radius: 5px;
            font-weight: 500;
            color: #ffffff;
        }
    }

    // 优惠券弹窗
    .CouponModel {
        min-height: 150px;
        padding: 20px;

        .title {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            .sp1 {
                height: 16px;
                width: 5px;
                background-color: #5f7dff;
                border-radius: 3px;
                margin-right: 8px;
            }
            .sp2 {
                font-size: 18px;
            }
        }

        // 优惠券卡片
        .couponCard {
            max-height: 50vh;
            overflow-y: scroll;
        }

        .closeBtn {
            height: 36px;
            font-size: 15px;
            line-height: 36px;
            background: #5f7dff;
            border-radius: 6px;
            text-align: center;
            color: #fff;
        }
    }

    // 客服弹窗
    .keFuPopup{
        height: 75vh;
        .keFu_title{
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 10px;
        }

        .keFu_iframe{
            width: 100%;
            height: 100%;
            height: calc(75vh - 40px);
        }
    }
}
</style>
