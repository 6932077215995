<template>
    <div class="classPlan">
        <div class="item" v-for="(item,i) in detailData.courseScheduleList" :key="i">
            <div class="p1" @click.stop="onTitle1(i)">
                <span class="ellipsis">{{item.courseName}}</span>
                <van-icon :name="item.isShowSub == true? 'arrow-up' : 'arrow-down'" size="18px" />
            </div>
            
            <div v-show="item.isShowSub">
                <div v-for="(item2,j) in item.chapterList" :key="j">
                    <div class="p2" @click.stop="onTitle2(i,j,item2)">
                        <p class="p2_p">
                            <span class="ellipsis">{{item2.chapterName}}</span>
                            <van-icon :name="item2.isShowSub == true? 'arrow-up' : 'arrow-down'" size="18px" />
                        </p>
                    </div>

                    <div v-show="item2.isShowSub" class="conten2">
                        <div v-for="(item3,k) in item2.classHourList" :key="k">
                            <div class="p3">
                                <p class="p3_p">
                                    <span class="ellipsis">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{item3.classHourName}}</span>
                                    <img v-if="detailData.isBuy==1 || detailData.isFree==0 || item3.isAudition == 1" @click="toVideo(item,item2,item3)" src="@/assets/all_Icon/drawable-xxhdpi/icon_paly.png" alt="#" style="width:20px;height:20px;margin-right:10px">
                                    <img v-else @click="toVideo('noBuy')" src="@/assets/all_Icon/drawable-xxhdpi/icon_lock.png" alt="#" style="width:20px;height:20px;margin-right:10px">
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        
    </div>
</template>

<script>

export default {
    props:['detailData','coursePackageId'],
    data() {
        return { 
            // 套餐id:coursePackageId > 课程id:courseId > 章节id:chapterId
        }
    },
    created() {
        // 添加isShowSub属性，控制列表打开或关闭
        this.detailData.courseScheduleList.forEach((item,i) => {
            this.$set(item,"isShowSub",false)
            for(var j = 0; j<item.chapterList.length; j++){
                this.$set(item.chapterList[j],"isShowSub",true)
            }
        });
    },
    methods: {
        onTitle1(index) {
            this.detailData.courseScheduleList[index].isShowSub = !this.detailData.courseScheduleList[index].isShowSub
        },
        onTitle2(i, j) {
            this.detailData.courseScheduleList[i].chapterList[j].isShowSub = !this.detailData.courseScheduleList[i].chapterList[j].isShowSub
        },
        // 跳视频播放页
        toVideo(item,item2,item3){
            console.log('选课中心-跳视频播放页---',item,item2,item3)
            if(item == 'noBuy') return this.$toast('未解锁该课程')
            
            this.$router.push('/videoPage/'+ this.coursePackageId+ '/'+ item.courseId+ '/'+ item3.classHourId)
        }
    },
}
</script>

<style lang="less" scoped>
.classPlan {
    font-family: PingFangSC-Medium, PingFang SC;

    .item {
        margin-bottom: 10px;
        color: #333333;
    }
    .p1 {
        height: 44px;
        font-size: 15px;
        // font-weight: bold;
        color: #333333;
        background-color: #fff;
        padding: 0 20px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > span{
            width: 270px;
        }
    }
    .p2 {
        font-size: 14px;
        background-color: #fff;
        padding: 0 30px;

        .p2_p{
            height: 44px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 0.5px solid #ccc;
        }
    }
    .conten2 {
        background-color: #fff;
    }

    .p3 {
        font-size: 13px;
        padding: 0 30px;

        .p3_p{
            height: 44px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 0.5px solid #ccc;
        }
    }
}
</style>
