<template>
    <div class="couponCard2" :class="data.isReceive == 0? 'noReceiveBGC' : 'receiveBGC'" @click="receiveCoupon">

        <div class="left">
            <div class="left_div">
                <span class="left_sp1">¥</span>
                <span class="left_sp2">{{data.price}}</span>
            </div>
        </div>

        <div class="right">
            <div style="margin-bottom:10px;">满{{data.enablePrice}}减{{data.price}}卷</div>
            <div>有效期{{data.effectiveDay}}天</div>
        </div>

    </div>
</template>

<script>
export default {
    props:{
        data:{
            type:Object,
            default:()=>{}
        }
    },
    data(){
        return {
            receive:0,
        }
    },
    methods: {
        receiveCoupon(){
            if(this.data.isReceive == 1) return this.$toast('已领取')
            this.$emit('receiveCoupon',this.data.courseCouponId)
        }
    },
}
</script>

<style lang="less" scoped>
// 未领取背景
.noReceiveBGC{
    background: url('../../../assets/all_Icon/drawable-xxhdpi/bg_card_course_one.png') no-repeat;
    background-size:100% 100%;
}
// 已领取背景
.receiveBGC{
    background: url('../../../assets/all_Icon/drawable-xxhdpi/bg_card_course_one_over.png') no-repeat;
    background-size:100% 100%;
}

.couponCard2{
    height: 80px;
    margin-bottom: 20px;
    border-radius: 6px;
    display: flex;

    .left{
        width: 40%;
        display: flex;
        color: #7a7a7a;

        .left_div{
            margin-left: 15px;
            display: flex;
            align-items: center;
            justify-content: center;

            .left_sp1{
                font-size: 15px;
            }
            .left_sp2{
                font-size: 30px;
                font-weight: 500;
            }
        }

        
    }
    .right{
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #7a7a7a;
        font-size: 14px;
    }
}
</style>

